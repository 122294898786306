import React, {useContext, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Wrapper, BurgerMenu} from './styles';
import Container from '../Grid/Container';
import Column from '../Grid/Column';
import MainNav from '../Navigation/MainNav';
import MetaNav from '../Navigation/MetaNav';
import ResponsiveSwitch from '../ResponsiveSwitch';
import {Row} from '../Grid';
import Burger from '../Burger';
import BurgerNav from '../Navigation/BurgerNav';
import Logo from '../Logo';
import {startScrolling, stopScrolling} from '../../../utils/scroll-utils';
import LocationContext from '../../utils/LocationContext';
import StickyPoints from './StickyPoints';

const Navbar = ({isLoggedIn, profile, userRoles}) => {
    const [isOpen, setIsOpen] = useState(false);
    const location = useContext(LocationContext);
    const toggleBurgerMenu = () => {
        // Start and stop scrolling ONLY on user interaction
        // otherwise would disturb starting/stopping scrolling
        // implemented in cookie-consent saga.
        const newIsOpen = !isOpen;
        if (newIsOpen) {
            stopScrolling();
        } else {
            startScrolling();
        }
        setIsOpen(newIsOpen);
    };
    useEffect(() => {
        // Whenever the user clicks on a link in the navbar and a
        // navigation occurs, then the navbar will unmount, and a new navbar
        // on another page (layout.js) is being mounted. This will intentionally
        // cause the previous navbar to "close". So the new navbar has a fresh isOpen
        // state but the scrolling is still stopped.
        // To fix this, we need to start scrolling again when the old navbar unmounts:
        return () => {
            startScrolling();
        };
    }, []);

    return (
        <ResponsiveSwitch>
            {(match) => {
                if (match.xl) {
                    return (
                        <Wrapper hideShadow={false}>
                            <Container width="wide" noGutter>
                                <Row>
                                    <Column>
                                        <MainNav
                                            location={location}
                                            loggedIn={isLoggedIn}
                                            userRoles={userRoles}
                                        />
                                    </Column>
                                    <Logo withSubtitle />
                                    {isLoggedIn && (
                                        <StickyPoints
                                            points={
                                                profile ? profile.points : 0
                                            }
                                        />
                                    )}
                                    <Column>
                                        <MetaNav
                                            location={location}
                                            loggedIn={isLoggedIn}
                                            username={
                                                profile && profile.username
                                            }
                                            userRoles={userRoles}
                                        />
                                    </Column>
                                </Row>
                            </Container>
                        </Wrapper>
                    );
                }
                return (
                    <>
                        <BurgerMenu isOpen={isOpen}>
                            <BurgerNav
                                location={location}
                                loggedIn={isLoggedIn}
                                username={profile && profile.username}
                                userRoles={userRoles}
                            />
                        </BurgerMenu>
                        <Wrapper hideShadow={isOpen}>
                            <Container width="wide" noGutter>
                                <Row>
                                    <Column>
                                        <Logo />
                                    </Column>
                                    <Column>
                                        <Burger
                                            isOpen={isOpen}
                                            onClick={toggleBurgerMenu}
                                        />
                                    </Column>
                                </Row>
                            </Container>
                        </Wrapper>
                    </>
                );
            }}
        </ResponsiveSwitch>
    );
};

Navbar.propTypes = {
    isLoggedIn: PropTypes.bool,
    profile: PropTypes.object,
    userRoles: PropTypes.array,
};

Navbar.defaultProps = {
    isLoggedIn: false,
    userRoles: [],
};

export default Navbar;
